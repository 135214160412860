<template>
  <div class="share-container">
    <!-- 课件列表 -->
    <div class="share">
      <!-- 标题 -->
      <div class="title-container d-f a-c j-b">
        <div class="title">笔记下载</div>
        <button @click="downloadAll" v-if="shareNotes.length > 0">全部下载</button>
      </div>
      <!-- 有数据情况 -->
      <ul class="file-list d-f f-w" v-if="shareNotes.length > 0">
        <li v-for="(item, index) in shareNotes" :key="index">
          <div class="top" v-if="item.type === 'image'">
            <img :src="item.url" :alt="item.filename" class="img" />
          </div>
          <div class="top" v-else-if="item.type === 'video'">
            <video :src="item.url" class="img"></video>
          </div>
          <div class="top" v-else-if="item.type === 'ppt'">
            <div class="icon">
              <img src="@/assets/image/share_ppt.png" />
            </div>
          </div>
          <div class="top" v-else-if="item.type === 'doc'">
            <div class="icon">
              <img src="@/assets/image/share_doc.png" />
            </div>
          </div>
          <div class="top" v-else-if="item.type === 'excel'">
            <div class="icon">
              <img src="@/assets/image/share_xls.png" />
            </div>
          </div>
          <div class="top" v-else-if="item.type === 'pdf'">
            <div class="icon">
              <img src="@/assets/image/share_pdf.png" />
            </div>
          </div>
          <div class="top" v-else>
            <div class="icon">
              <img src="@/assets/image/share_unknown.png" />
            </div>
          </div>
          <div class="middle">{{ item.filename }}</div>
          <div class="bottom d-f a-c j-c">
            <button @click="download(item)">点击下载</button>
          </div>
        </li>
      </ul>
      <!-- 无数据情况 -->
      <div class="no-data-con" v-else>
        <div class="no-data">
          <img src="@/assets/image/share_no_data.png" alt="无数据" />
        </div>
        <div class="invalid">分享已经失效</div>
      </div>
    </div>
    <!-- 微信浏览器遮罩层 -->
    <div class="dust" v-if="dust">
      <img src="@/assets/image/share_guide.png" class="guide" />
      <img src="@/assets/image/share_know.png" class="know" @click="iKnow" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Share",
  data() {
    return {
      dir: "",
      shareNotes: [],
      dust: true,
    };
  },
  mounted() {},
  created() {
    this.dir = this.getQueryVariable("dir");
    this.queryShareNotes();
    this.weixinTip();
  },
  methods: {
    //获取地址栏参数
    getQueryVariable(variable) {
      let query = window.location.search.substring(1);
      let arr = query.split("&");
      for (let i = 0; i < arr.length; i++) {
        var pair = arr[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    //获取课件列表
    queryShareNotes() {
      this.$api.queryShareNotes({ dir: this.dir }).then((res) => {
        this.shareNotes = res.data;
      });
    },
    //下载课件
    download(item) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = item.url;
      link.setAttribute("download", item.filename); // 动态设置原文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    //全部下载课件
    downloadAll() {
      this.shareNotes.forEach((r, i) => {
        setTimeout(() => {
          console.log(r, i);
          this.download(r);
        }, 1000 * i);
      });
    },
    //判断是否是微信浏览器
    weixinTip() {
      var ua = navigator.userAgent;
      var isWeixin = !!/MicroMessenger/i.test(ua);
      if (isWeixin) {
        this.dust = true;
      } else {
        this.dust = false;
      }
    },
    //点击我知道了按钮
    iKnow() {
      this.dust = false;
    },
  },
};
</script>

<style scoped lang="less">
.share-container {
  .share {
    width: 100vw;
    padding: 45px 0; /*no*/
    box-sizing: border-box;
    .title-container {
      width: 100%;
      height: 30px; /*no*/
      padding: 0 20px; /*no*/
      box-sizing: border-box;
      margin-bottom: 24px; /*no*/
      .title {
        font-size: 20px; /*no*/
        font-weight: 400;
        color: #333333;
        opacity: 1;
      }
      button {
        width: 80px; /*no*/
        height: 25px; /*no*/
        background: #e0ece9;
        border: 1px solid #cae5de; /*no*/
        opacity: 1;
        border-radius: 3px; /*no*/
        font-size: 12px; /*no*/
        font-weight: 400;
        color: #129f72;
        opacity: 1;
        outline: none;
      }
    }
    .file-list {
      width: 100%;
      li {
        margin-left: 18px; /*no*/
        width: 43%;
        .top {
          width: 100%;
          padding-bottom: 56.3%;
          background: #f3f4f6;
          position: relative;
          .img {
            position: absolute;
            width: 100%;
            height: 100%;
          }
          .icon {
            position: absolute;
            width: 33.1%;
            height: 61.7%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .middle {
          width: 100%;
          margin-top: 8px; /*no*/
          font-size: 12px; /*no*/
          font-weight: 400;
          color: #7d7d7d;
          opacity: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          text-align: center;
        }
        .bottom {
          width: 100%;
          margin-top: 4px; /*no*/
          margin-bottom: 16px; /*no*/
          button {
            width: 80px; /*no*/
            height: 25px; /*no*/
            background: #e0ece9;
            font-weight: 400;
            color: #129f72;
            border: 1px solid #cae5de; /*no*/
            opacity: 1;
            border-radius: 3px; /*no*/
            font-size: 12px; /*no*/
            outline: none;
          }
        }
      }
    }
    .no-data-con {
      width: 100%;
      margin-top: 106px; /*no*/
      position: relative;
      .no-data {
        width: 41.6%;
        transform: translate(50%);
        img {
          width: 100%;
        }
      }
      .invalid {
        font-size: 16px; /*no*/
        font-weight: 400;
        color: #2d2e2e;
        margin-top: 24px; /*no*/
        position: absolute;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
  .dust {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    .guide {
      position: absolute;
      right: 17px; /*no*/
      width: 280px; /*no*/
      height: 188px; /*no*/
    }
    .know {
      position: absolute;
      top: 212px; /*no*/
      left: 50%;
      width: 140px; /*no*/
      height: 42px; /*no*/
      transform: translate(-50%);
    }
  }
}
</style>
